<template>
  <stats :data="data" />
</template>

<script>
// import { BCard, BCardText, BLink } from 'bootstrap-vue';
import store from '@store';
import stats from './admin/Dashboard/Stats.vue';

export default {
  components: {
    stats,
    // BCard,
    // BCardText,
    // BLink,
  },
  data() {
    return {
      data: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 0,
          subtitle: 'Data Points',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '2',
          subtitle: 'Businesses',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '17',
          subtitle: 'Nodes',
          customClass: 'mb-2 mb-sm-0',
        },
      ],
    }
  },
  async created() {
    const calls = [
      store.dispatch('getDataCount'),
      store.dispatch('getBusinesses'),
      store.dispatch('getNodes'),
      store.dispatch('getUsers'),
      store.dispatch('getApiKeys'),
    ]
    await Promise.all(calls);

    this.data[0].title = store.state.nodes.dataCount;
    this.data[1].title = store.state.businesses.businesses.length;
    this.data[2].title = store.state.nodes.nodes.length;
  },
}
</script>

<style>

</style>
